import { FC } from "react";

import CircledIcon from "./CircledIcon";
import { Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  region: Region;
  size: Sizes;
}

const RegionIcon: FC<Props> = ({ region, size }) => (
  <div className={classes.regionWrapper} key={region.id}>
    {Boolean(region.parentRegion) && (
      <CircledIcon
        src={region.parentRegion.icon}
        size={size}
        className={classes.parentRegion}
      />
    )}
    <CircledIcon src={region.icon} size={size} />
  </div>
);

export default RegionIcon;
