import { FC } from "react";
import joinClassNames from "classnames";

import EmptyPaymentImageIcon from "icons/empty-payment.svg?react";

import Icon from "./Icon";
import { BaseProps, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends Omit<BaseProps, "altImage"> {
  className?: string;
  size?: Sizes;
  title?: string;
}

const PaymentMethodIcon: FC<Props> = ({
  size = "sm",
  className,
  ...restProps
}) => (
  <Icon
    data-size={size}
    alt="Payment method icon"
    className={joinClassNames(
      classes.sizePresets,
      classes.paymentIcon,
      className,
    )}
    altImage={EmptyPaymentImageIcon}
    {...restProps}
  />
);

export default PaymentMethodIcon;
